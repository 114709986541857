import React, { useState } from 'react';
import MainImage from '../../images/portfolio/NRK/nrk_video.png';
import Concept1 from '../../images/portfolio/NRK/concept_1.jpg';
import IllustrationAirplane from '../../images/portfolio/NRK/airplane.png';
import IllustrationSpaceStation from '../../images/portfolio/NRK/space_station.png';
import PortfolioPage from '../../components/PortfolioPage';

const PortfolioNRK = () => {    
    return <PortfolioPage img={MainImage} externalLink={true} url="https://www.nrk.no/video/her-faar-andreas-wahl-oppfylt-drommen-om-aa-vaere-vektlos_c509007d-f27b-4ea0-9fb8-a2100a9a993e"  title="NRK" description={
      <div>
        <p>
           “Andreas Wahl har alltid drømt og å få være vektløs som en astronaut. Nå har han
           fått sjansen til å forklare hvorfor vektløshet er det samme som å være i fritt fall.” 
        </p>
        <br />
        <p>
          NRK produserte en animasjonsfilm med Andreas Wahl, som forklarer ‘vektløshet’. 
          I filmen besøker Andreas Wahl Frankrike, for å prøve Air Zero G sin simulasjon av vektløshet. 
          Filmen vår illustrerer prosessen av at flyet når toppen av en bue, skrur av strømmen for å gå i fritt fall, 
          for så å skru på strømmen igjen.
        </p>
        <br />
        <p>
          Den andre snutten tar for seg hvordan det samme gjelder for romstasjonen i verdensrommet, 
          som er i konstant fritt fall rundt jordkloden.
        </p>
        <p>
          (2022)
        </p>
      </div>
    }>
         <div className=" w-full mt-32 lg:px-0">
            <div className="flex flex-wrap">
              <img className="max-w-full mb-12 mr-8 md:max-w-2xl" src={Concept1}  />
              <div >
                <div>
                  <p className="mb-6">AIR ZERO G Flyet</p>
                  <img src={IllustrationAirplane} className="max-w-full md:max-w-md mb-8" />
                </div>
                <div>
                  <p className="mb-6">Romstasjonen</p>
                  <img src={IllustrationSpaceStation} className="max-w-full md:max-w-md" />
                </div>
           
              </div>
            </div>
            
          </div>
    </PortfolioPage>
}

export default PortfolioNRK;